<template>
  <div class="das_top_cont">
    <div class="history_box">
      <div class="accountright_cont_box">
        <div class="flex_center_between_box">
          <h2 class="history_box-head">CSI Customer Care FAQ</h2>
        </div>
        <div class="faq_box">
            <el-collapse v-model="activeNames">
         
                <el-collapse-item  class="item_title"  title="What is CSI?" >
                  <div class="item_cont_txt">CSI is a premium next-generation global B2B intelligence platform that is accessed by a subscription. Our priority is to provide a product that allows our subscribers to know their customer and to expand their business. CSI has a database of over 400 million companies in over 200 countries, providing corporate evaluations on a macro level.</div>
                </el-collapse-item>    
            
                <el-collapse-item  class="item_title"  title="What can CSI do for me and my company?" >
                  <div class="item_cont_txt">CSI will provide the insight you need to check, manage, verify, research and analyze the operational risks you need to be aware of on the companies you want to do business with.</div>
                  <div class="item_cont_txt">Benefits of subscribing to CSI:</div>
                  <ul style="list-style-type: circle;padding-left: 25px;">
                    <li style="list-style-type: disc; " class="item_cont_txt"> Verify.<span style="font-style: italic;"> Confirm the existence of a company, location and operations.</span></li>
                    <li style="list-style-type: disc; " class="item_cont_txt">Analyze. <span style="font-style: italic;">Review operations, finances, trends and sustainability.</span></li>
                    <li style="list-style-type: disc; " class="item_cont_txt">Quantify. <span style="font-style: italic;">Compare your customers to their peers and within their industry or region.</span></li>
                    <li style="list-style-type: disc; " class="item_cont_txt">Legal and Compliance. <span style="font-style: italic;">Confirm legal status and negative records.</span></li>
                    <li style="list-style-type: disc; " class="item_cont_txt">Monitor. <span style="font-style: italic;">We are your business drone and provide reconnaissance for trends with many data points.</span> </li>
                    <li style="list-style-type: disc; " class="item_cont_txt">Marketing. <span style="font-style: italic;">CSI is a powerful tool to frame your client base and expand sales to a qualified customer base.  This allows your organization to promote your own most relevant business information.</span></li>
                  </ul>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="How can I access to the CSI platform?" >
                  <div class="item_cont_txt">The platform provides new users with a review of 4 companies without charge, allowing you to test the service for free. Thereafter, registration is required and various levels of subscription are offered, providing access to all of CSI’s services.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="Can I apply for a group account? ">
                  <div class="item_cont_txt">Our subscriptions are available from 1 user to as many users that are needed to accommodate your corporate needs.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="Is my account protected?" >
                  <div class="item_cont_txt">Yes, only named subscribers have access to the account. Scammers try to use a variety of methods (e.g., fake emails and websites) to gain access to subscribers' personal information. CSI will never ask for your password, credit card number or any other personal information in an email. We will never disclose your personal information to other companies or individuals.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="What can I do if someone else used my account?" >
                  <div class="item_cont_txt">If you suspect someone used your account without your consent, please follow the steps below:</div>
                  <div style="margin-bottom:5px ;" class="item_cont_txt">1.If you can still sign into your account, change your password immediately.</div>
                  <div style="margin-bottom:5px ;"  class="item_cont_txt">2.If your password has been changed, contact our service team by sending an email to <a href="mailto:service@csibizinfo.com" class="link">service@csibizinfo.com</a>. Your account will be temporarily suspended. We will send you a temporary password. After receiving your temporary password, you will need to sign in and set up a new password.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="How do I purchase a report from CSI?" >
                  <div class="item_cont_txt">When researching a company, tap the “Download” button on the top right of the screen. An online report will be generated and ready for download. You can also tap the “Update” button on the top right of the screen to order an offline report. For the latter, fresh research and an investigation will be conducted upon order by our local partners. It normally takes about 72 hours to deliver the update credit report for you.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="What are the differences between the reports?" >
                  <div class="item_cont_txt">There are two types of reports:</div>
                  <div style="margin-bottom:5px ;"  class="item_cont_txt">1.Online Report: It contains all available data online that is showing on your screen. Besides a PDF download report, you can also export data in Excel format. </div>
                  <div style="margin-bottom:5px ;"  class="item_cont_txt">2.Offline Report: It also called Update Report. It is investigated and updated upon orders. An offline report contains all the information you received online plus analytics, reference and verification information from our service providers. </div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="Does CSI analyze public companies or private companies?" >
                  <div class="item_cont_txt">We provide full operational analysis on all publicly traded companies and private companies where the data is available.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="How does CSI rate a company?" >
                  <div class="item_cont_txt">Credit ratings or scores are provided by local professional credit bureau or agencies. A credit rating and credit score is a good tool to determine a company’s credit standing including short-term liquidity and possibility of default. ESG rating is also available in certain countries.</div>
                </el-collapse-item>
           
                <el-collapse-item  class="item_title"  title="How can I contact CSI’s customer services?" >
                  <div class="item_cont_txt">By clicking on the “Help” button on the bottom right of the screen, you can access items that are commonly inquired about. You can always reach us at <a href="mailto:request@csibizinfo.com" class="link">request@csibizinfo.com </a>. You can also find the information to contact our service team on line or directly over the phone.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="What if I don't want my company to appear on CSI?" >
                  <div class="item_cont_txt">At minimum, we try to provide the fundamental corporate information available in public records, published by the government or other official registers. Our subscribers operate in an open marketplace and providing data that is available from other public sources is a minimum service that is available on our platform.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="What if we need to contact a company researched on CSI?" >
                  <div class="item_cont_txt">In most cases, the Overview section in the Corporate Snapshot mode will provide company address, website and other contact information. You can also export the list of contact info you searched.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="Is CSI’s data reliable? Where does the data come from?" >
                  <div class="item_cont_txt">We collect our data via various public sources and where needed use vetted data suppliers worldwide. We grow our database organically with over a decade of data collection. This allows us to expand and populate our database with essential information points better in the industry.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="The data on the CSI report is out of date. Can I request an update report?" >
                  <div class="item_cont_txt">CSI is constantly gathering, processing and updating the data on the companies we provide information on. If you are reviewing a company where the information is out of date, please go to the Company Snap Shot page and simply click on the “Update” button on the top right. This will order an updated report which will be provided to you in a few days.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="What if the data on the CSI report is incomplete of incorrect?" >
                  <div class="item_cont_txt">We welcome our subscribers to provide input they may have on a company, or their own company, to correct or complete the data we have on file. Simply go to the Company Snapshot page and click on the “Report Error” button. In the Report Error mode, subscribers can provide input on a variety of reporting subjects, including a mode to provide update/correct company information. All information received will be validated by the system via a verification process before data updated.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="Where can I find reports ordered?" >
                  <div class="item_cont_txt">You can download a report directly from the search-results screen (arrow down). The credit automatically will be deducted, after which you see the request in ‘my account’. From there you can download it again.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="How can I upgrade my subscription?" >
                  <div class="item_cont_txt">You can upgrade your subscription at any time. Just tap “Add Credit” in My Account, choose your credits you want to charge. You can view your purchase in “Billing History” in My Account.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="Is my credit card detail kept and what do I need to do to change them?" >
                  <div class="item_cont_txt">For data protection, CSI will not store users’ credit card details in our system. Users need to input their credit card info when recharging credits.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="How long will my purchased credits remain available?" >
                  <div class="item_cont_txt">Once purchased, it will remain at your disposal indefinitely as long as you use your account at least once a year. When an account has not been used for a period of 720 days following the date of purchase, the account will be deleted and any credits will be lost.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="What if I want to change the accounts name and/or contact details?" >
                  <div class="item_cont_txt">As the account only can be accessed by those who have created an account and subsequentially set the log-in details, please write an email to   <a href="mailto:service@csibizinfo.com" class="link">service@csibizinfo.com</a>  and state which details you would like to have changed. Our service representative will get in contact with you.</div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="How can I get a printed/downloadable invoice for a purchase?" >
                  <div class="item_cont_txt">Apart from the charge to you credit card, an invoice can be produced by going to “Billing History” in My Account and download your receipts.</div>
                </el-collapse-item>

                <el-collapse-item  class="item_title"  title="Can I get a refund of a paid subscription, when I have decided to not use it any further?" >
                  <div class="item_cont_txt">As the service is rendered at exceptionally low-price levels, this is not possible for the online available subscriptions. For tailor-made restitutions, please contact <a href="mailto:request@csibizinfo.com" class="link">request@csibizinfo.com </a>. </div>
                </el-collapse-item>
                <el-collapse-item  class="item_title"  title="Can I share acquired reports?" >
                  <div class="item_cont_txt">Information obtained on the CSI platform is for our subscribers and their internal use and consideration only.</div>
                </el-collapse-item>
                <!-- <a href="mailto:example@example.com?subject=主题&body=邮件内容">发送邮件</a> -->
            </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNames: "",
      attentionDialog: false, //弹框
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
<style scoped>
.title {
  font-family: 'DMSans Bold';
  margin-top: 1.5714285714285714rem;
  margin-bottom: 1.4285714285714286rem;
}
.el-collapse {
  border: none !important;
  font-family: "Arial";
}
.item_title /deep/ .el-collapse-item__header {
  font-size: 1.4285714285714286rem;
  color: #022955;
  font-family: "Arial";
  /* margin: 0.7142857142857143rem 0; */
  height: 1.4285714285714286rem;
background-color: #F5F5F5;
  border: none !important;
}
.item_title{
padding: 1.4285714285714286rem ;
background-color: #F5F5F5;
margin-bottom: 0.7142857142857143rem;
}
.item_title.el-collapse-item.is-active:last-child
  /deep/
  .el-collapse-item__wrap,
.item_title:last-child /deep/ .el-collapse-item__header {
  border-width: 0;
    border: none !important;
}
.item_title /deep/ .el-collapse-item__header.is-active {
  border-bottom-color: transparent;
}
.item_cont_title {
  /* font-family: "Arial Bold"; */
  font-family: "Arial";
  /* margin-bottom: 0.7142857142857143rem; */
  color: #022955;
}
.item_cont_txt {
font-size: 1.2857142857142858rem;
color: #1A2332;
background-color: #F5F5F5;
padding-top: 1.1428571428571428rem;
}

@media screen and (max-width: 1441px) {
  /* 时间，内容，箭头 */
  .item_cont_txt {
    font-size: 12px !important;
  }


}
.title_txt img {
  width: 1.4285714285714286rem;
  margin-right: 0.5714285714285714rem;
}
.item_title /deep/.el-collapse-item__content {
  padding-bottom: 0;
}

/* .item_title /deep/ .el-collapse-item__arrow {
    width: 1.7142857142857142rem;
    height: 1.7142857142857142rem;
    background: #ffffff;
    border-radius: 0.5714285714285714rem;
    border: 0.07142857142857142rem solid #e7eaed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 0.6428571428571429rem;
} */
/* .item_title /deep/ .el-collapse-item__arrow::before {
    content: '';
}
*/
.item_title /deep/ .el-collapse-item__arrow {
     transform: rotate(90deg);
}

.item_title /deep/ .el-collapse-item__arrow.is-active {
    transform: rotate(-90deg);
} 

.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}
.link {
  color: #1290c9;
  text-decoration: underline;
}
.head-text{
  font-family: 'DMSans Bold';
font-size: 1.4285714285714286rem !important;
text-align: left;
font-style: normal;
color: #1A2332;
line-height: 1.6428571428571428rem;
}
.icon-time{
    font-size: 1.1428571428571428rem;
line-height: 1.6428571428571428rem;
color: #999999;
}
.message-right{
    margin-left: 1.4285714285714286rem;
}
ul{
      background-color: #F5F5F5;
}
/deep/ .el-collapse-item__wrap {
  border: none !important;
}
</style>